import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import SEO from "../components/SEO"
import { PageSegment, TitleAndDescription, Pagination } from "../components"
import { GridOneColumn } from "../styles"
import { SubscribeNewsletter } from "../components/homePage"

const PostsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 90%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const PostLink = styled(Link)`
  text-decoration: none;
  color: var(--black);
  margin: 10px;
  border: 1px solid var(--black);
  width: 280px;
  height: 448px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 100%;
    height: 298px;
  }

  .textContainer {
    border-top: 1px solid var(--black);

    width: 100%;
    flex: 1 1 auto;
    h3 {
      text-align: center;
      margin-top: 20px;
      font-weight: 600;
    }
    p {
      margin: 20px;
      text-align: justify;
    }
  }
`

export default function Blog301({ data, pageContext }: any) {
  const posts = data.posts

  return (
    <>
    <SEO title="301 Blog" />
      <PageSegment>
        <GridOneColumn>
          <TitleAndDescription title="301 Blog" />
          <PostsContainer>
            {posts.nodes.map((node: any) => {
              return (
                <PostLink key={node.id} to={`/301-blog/${node.slug.current}`}>
                  <Img
                    fluid={node.postBanner.asset.fluid}
                    alt={node.title}
                    className="image"
                  />
                  <div className="textContainer">
                    <h3>{node.title}</h3>
                    <p>{node.description}</p>
                  </div>
                </PostLink>
              )
            })}
          </PostsContainer>
          <Pagination
            pageSize={parseInt(process.env.GATSBY_BLOG_PAGE_SIZE!)}
            totalCount={posts.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base="/301-blog"
          />
        </GridOneColumn>
      </PageSegment>
      <SubscribeNewsletter />
    </>
  )
}

export const query = graphql`
  query($skip: Int = 0, $pageSize: Int = 3) {
    posts: allSanityPost(
      limit: $pageSize
      skip: $skip
      sort: { order: DESC, fields: publishDate }
    ) {
      totalCount
      nodes {
        title
        id
        description
        slug {
          current
        }
        postBanner {
          asset {
            fluid(maxHeight: 400, maxWidth: 350) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
